<template>
  <div class="home">
    <div class="fog"></div>
    <div class="fog-2"></div>
    <img src="~@/assets/logo/logo.png" class="logo" alt="Thrones Wars" />
    <div class="window menu">
      <div class="window-frame top-left"></div>
      <div class="window-frame top"></div>
      <div class="window-frame top-right"></div>
      <div class="window-frame left"></div>
      <div class="window-frame body">
        <router-link to="Game" class="button game">single player</router-link>
        <br />
        <router-link to="Game" class="button game">multiplayer</router-link>
      </div>
      <div class="window-frame right"></div>
      <div class="window-frame bottom-left"></div>
      <div class="window-frame bottom"></div>
      <div class="window-frame bottom-right"></div>
      <div class="nail top-left"></div>
      <div class="nail top-right"></div>
      <div class="nail bottom-left"></div>
      <div class="nail bottom-right"></div>
      <div class="decor left"></div>
      <div class="decor right"></div>
      <div class="decor bottom"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style lang="scss" scoped>
.home {
  background-image: url("~@/assets/backgrounds/home.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  box-shadow: inset 0 0 20vw black;
  overflow: hidden;
}
.fog {
  background-color: #000;
  background-image: url("~@/assets/backgrounds/fog.jpg");
  background-repeat: repeat;
  background-position: left center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 200vw;
  height: 100vh;
  opacity: 0.5;
  z-index: 1;
  animation: fog 240s ease-in-out infinite;
}
.fog-2 {
  background-color: #000;
  background-image: url("~@/assets/backgrounds/fog-2.jpg");
  background-repeat: repeat;
  background-position: left center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 200vw;
  height: 100vh;
  opacity: 0.5;
  z-index: 1;
  animation: fog 120s ease-in-out infinite;
}
@keyframes fog {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-100vw, 0, 0);
  }
  0% {
    transform: translate3d(0, 0, 0);
  }
}
.logo {
  width: 720px;
  height: auto;
  margin: 0;
  position: absolute;
  top: 20px;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 3;
}
.menu {
  position: absolute;
  top: 277px;
  left: 50%;
  margin: 0;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 2;
}
</style>
