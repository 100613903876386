<template>
  <div class="game">
    <div class="controls">
      <button :disabled="map.config.zoom == 1" @click="zoomOut">-</button>
      <button :disabled="map.config.zoom == 4" @click="zoomIn">+</button>
    </div>
    <div class="cross">+</div>
    <div id="map">
      <div
        id="map-content"
        :style="
          'width: ' +
            map.config.tileWidth * map.config.sizeX * map.config.zoom +
            'px; height: ' +
            map.config.tileHeight * map.config.sizeY * map.config.zoom +
            'px; background-size: ' +
            map.config.tileWidth * map.config.zoom +
            'px;'
        "
      >
        <div
          v-for="chunk in map.chunks"
          :key="chunk.id"
          :id="'chunk_' + chunk.id"
          class="chunk"
          :style="
            'width: ' +
              map.config.tileWidth * map.config.lenX * map.config.zoom +
              'px; height: ' +
              map.config.tileHeight * map.config.lenY * map.config.zoom +
              'px; ' +
              'top: ' +
              chunk.top +
              'px; ' +
              'left: ' +
              chunk.left +
              'px;'
          "
        ></div>
        <div
          v-for="x in map.scaleX"
          :key="'sacale_x_' + x.id"
          :id="'scale_x_' + x.id"
          class="scale-x"
          :style="
            'left: ' +
              (x.pos - (map.config.tileWidth * map.config.zoom) / 2) +
              'px; width: ' +
              map.config.tileWidth +
              'px; height: ' +
              map.config.tileWidth +
              'px;'
          "
        >
          <div
            class="left"
            :style="
              'width: ' +
                (map.config.tileWidth * map.config.zoom) / 2 +
                'px; height: ' +
                map.config.tileWidth / 2 +
                'px;'
            "
          ></div>
          <div
            class="bottom"
            :style="
              'width: ' +
                map.config.tileWidth * map.config.zoom +
                'px; height: ' +
                map.config.tileWidth / 2 +
                'px;'
            "
          >
            {{ x.id }}
          </div>
        </div>
        <div
          v-for="y in map.scaleY"
          :key="'sacale_y_' + y.id"
          :id="'scale_y_' + y.id"
          class="scale-y"
          :style="
            'top: ' +
              (y.pos - (map.config.tileHeight * map.config.zoom) / 2) +
              'px; width: ' +
              map.config.tileWidth +
              'px; height: ' +
              map.config.tileHeight * map.config.zoom +
              'px;'
          "
        >
          <div
            class="top"
            :style="
              'height: ' +
                (map.config.tileHeight * map.config.zoom) / 2 +
                'px; width: ' +
                map.config.tileWidth / 2 +
                'px;'
            "
          ></div>
          <div
            class="right"
            :style="
              'height: ' +
                map.config.tileHeight * map.config.zoom +
                'px; width: ' +
                map.config.tileWidth / 2 +
                'px; left: ' +
                map.config.tileWidth / 2 +
                'px; line-height: ' +
                map.config.tileHeight * map.config.zoom +
                'px;'
            "
          >
            {{ y.id }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Kinetic from "@buxlabs/kinetic";
import seedrandom from "seedrandom";

export default {
  name: "Game",
  data() {
    return {
      map: {
        config: {
          tileWidth: 32,
          tileHeight: 18,
          zoom: 1,
          sizeX: 256,
          sizeY: 256,
          totalChunks: 256,
          lenX: 16,
          lenY: 16,
          seed: "1234567890"
        },
        chunks: {
          0: {
            id: 0,
            top: 0,
            left: 0,
            pop: false
          }
        },
        center: {
          x: 256,
          y: 256
        },
        scaleX: {
          0: {
            id: 0,
            pos: 0
          }
        },
        scaleY: {
          0: {
            id: 0,
            pos: 0
          }
        },
        move: 0,
        tiles: [
          "map-grass-bush",
          "map-grass-mountain",
          "map-grass-mountain-1",
          "map-grass-mountain-2",
          "map-grass-mountain-3",
          "map-grass-trees",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass",
          "map-grass"
        ]
      }
    };
  },
  methods: {
    center: function(x = 0, y = 0) {
      let config = this.map.config;
      let center = this.map.center;
      let map = $("#map");
      let mapWidth = map.width();
      let mapHeight = map.height();
      let scrollLeft = (x * config.tileWidth * config.zoom) / 2 - mapWidth / 2;
      let scrollTop = (y * config.tileHeight * config.zoom) / 2 - mapHeight / 2;
      map.scrollLeft(scrollLeft).scrollTop(scrollTop);
      center.x = x;
      center.y = y;
    },
    updateCenter: function() {
      let config = this.map.config;
      let center = this.map.center;
      let map = $("#map");
      let mapWidth = map.width();
      let mapHeight = map.height();
      let scrollLeft = map.scrollLeft();
      let scrollTop = map.scrollTop();
      let x =
        ((scrollLeft + mapWidth / 2) / (config.tileWidth * config.zoom)) * 2;
      let y =
        ((scrollTop + mapHeight / 2) / (config.tileHeight * config.zoom)) * 2;
      center.x = x;
      center.y = y;
      console.log(x);
      console.log(y);
    },
    createScale: function() {
      let config = this.map.config;
      let contentWidth = config.tileWidth * config.sizeX * config.zoom;
      let maxX = contentWidth / (config.tileWidth * config.zoom);
      let xSize = contentWidth / maxX;
      for (let index = 0; index <= maxX * 2; index = index + 10) {
        this.map.scaleX[index] = {
          id: index,
          pos: (index * xSize) / 2
        };
      }
      let contentHeight = config.tileHeight * config.sizeY * config.zoom;
      let maxY = contentHeight / (config.tileHeight * config.zoom);
      let ySize = contentHeight / maxY;
      for (let index = 0; index <= maxY * 2; index = index + 10) {
        this.map.scaleY[index] = {
          id: index,
          pos: (index * ySize) / 2
        };
      }
    },
    zoomIn: async function() {
      const app = this;
      app.map.config.zoom++;
      await app.createChunks();
      app.center(app.map.center.x, app.map.center.y);
      app.chunkUpdate(true);
      app.createScale();
    },
    zoomOut: async function() {
      const app = this;
      app.map.config.zoom--;
      await app.createChunks();
      app.center(app.map.center.x, app.map.center.y);
      app.chunkUpdate(true);
      app.createScale();
    },
    isChunkOutViewport: function(chunkId) {
      const chunk = document.getElementById(chunkId);
      var rect = chunk.getBoundingClientRect();
      return (
        rect.bottom < 0 ||
        rect.right < 0 ||
        rect.left > window.innerWidth ||
        rect.top > window.innerHeight
      );
    },
    createChunks: function() {
      let x = 0;
      let y = 0;
      const maxXY = Math.sqrt(this.map.config.totalChunks);
      const chunkX = this.map.config.sizeX / maxXY;
      const chunkY = this.map.config.sizeY / maxXY;
      for (let cChunk = 0; cChunk < this.map.config.totalChunks; cChunk++) {
        $("#map-content #chunk_" + cChunk).html("");
        this.map.chunks[cChunk] = {
          id: cChunk,
          top: 0,
          left: 0,
          pop: false
        };
        this.map.chunks[cChunk].top =
          y * chunkY * this.map.config.tileHeight * this.map.config.zoom;
        this.map.chunks[cChunk].left =
          x * chunkX * this.map.config.tileWidth * this.map.config.zoom;
        this.map.chunks[cChunk].pop = false;
        x = x < maxXY - 1 ? x + 1 : 0;
        y = x == 0 ? y + 1 : y;
      }
    },
    populateFullMap: function(chunk) {
      let width = this.map.config.tileWidth * this.map.config.zoom;
      let height = this.map.config.tileWidth * this.map.config.zoom;
      let x = 0;
      let y = 0;
      let rng = seedrandom(this.map.config.seed + chunk);
      for (
        let index = 0;
        index < this.map.config.lenY * 2 * this.map.config.lenX;
        index++
      ) {
        let top =
          ((this.map.config.tileHeight / 2) * y -
            (this.map.config.tileWidth - this.map.config.tileHeight)) *
          this.map.config.zoom;
        let left = this.map.config.tileWidth * x * this.map.config.zoom;
        left =
          y % 2 === 0
            ? left
            : left + (this.map.config.tileWidth / 2) * this.map.config.zoom;
        let chunkZindexOffset =
          Math.floor(chunk / (this.map.config.sizeX / this.map.config.lenX)) *
          (this.map.config.lenY * 2);
        let max = this.map.tiles.length - 1;
        let min = 0;
        let randomTile = this.map.tiles[
          Math.floor(rng() * (max - min + 1) + min)
        ];
        if (randomTile != "map-grass") {
          let villageTile = `<i class='map-tile ${randomTile}' style='top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px; z-index: ${Math.floor(
            y + chunkZindexOffset
          )};'></i>`;
          $("#map-content #chunk_" + chunk).append(villageTile);
        }
        x = x < this.map.config.lenX - 1 ? x + 1 : 0;
        y = x == 0 ? y + 1 : y;
      }
      $("#map-content #chunk_" + chunk).removeClass("chunk-hidden");
    },
    chunkUpdate: function(reset = false) {
      const app = this;
      const chuncks = this.map.chunks;
      Object.keys(chuncks).forEach(function(key) {
        let pop = !reset ? chuncks[key].pop : false;
        if (!app.isChunkOutViewport("chunk_" + chuncks[key].id) && !pop) {
          app.populateFullMap(chuncks[key].id);
          chuncks[key].pop = true;
        } else if (app.isChunkOutViewport("chunk_" + chuncks[key].id)) {
          chuncks[key].pop = false;
          $("#map-content #chunk_" + key)
            .addClass("chunk-hidden")
            .html("");
        }
      });
    }
  },
  mounted: async function() {
    let app = this;
    app.map.move = app.map.config.tileWidth * app.map.config.zoom;
    const map = document.getElementById("map");
    new Kinetic(map, {
      cursor: "default",
      triggerHardware: true,
      moved: function() {
        if (app.map.move == app.map.config.tileWidth * app.map.config.zoom) {
          app.chunkUpdate();
          app.map.move = 0;
        }
        app.map.move++;
      },
      stopped: function() {
        app.chunkUpdate();
        app.map.move = app.map.config.tileWidth * app.map.config.zoom;
        app.updateCenter();
      }
    });
    await app.createChunks();
    await app.center(app.map.center.x, app.map.center.y);
    app.chunkUpdate();
    app.createScale();
  }
};
</script>

<style lang="scss">
#map {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
#map-content {
  background-image: url("~@/assets/tiles/map/map-background.png");
  background-repeat: repeat;
  background-position: left top;
  position: relative;
  background-color: #71826e;
  stroke: #646464;
  -webkit-text-stroke: 1px #404040;
  overflow: hidden;
}
.controls {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999999;
}
.chunk {
  position: absolute;
  opacity: 1;
  transition: opacity 0.3s, z-index 0.3s;
}
.chunk-hidden {
  opacity: 0;
  z-index: 0;
}
.map-tile {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}
.map-grass-bush {
  background-image: url("~@/assets/tiles/map/grass-bush.png");
}
.map-grass-fruit-bush {
  background-image: url("~@/assets/tiles/map/grass-fruit-bush.png");
}
.map-grass-mountain-1 {
  background-image: url("~@/assets/tiles/map/grass-mountain-1.png");
}
.map-grass-mountain-2 {
  background-image: url("~@/assets/tiles/map/grass-mountain-2.png");
}
.map-grass-mountain-3 {
  background-image: url("~@/assets/tiles/map/grass-mountain-3.png");
}
.map-grass-mountain {
  background-image: url("~@/assets/tiles/map/grass-mountain.png");
}
.map-grass-rock-1 {
  background-image: url("~@/assets/tiles/map/grass-rock-1.png");
}
.map-grass-rock {
  background-image: url("~@/assets/tiles/map/grass-rock.png");
}
.map-grass-tree {
  background-image: url("~@/assets/tiles/map/grass-tree.png");
}
.map-grass-trees {
  background-image: url("~@/assets/tiles/map/grass-trees.png");
}
.map-grass {
  background-image: url("~@/assets/tiles/map/grass.png");
}
.map-village-human {
  cursor: pointer;
  background-image: url("~@/assets/tiles/map/village-human.png");
}
.scale-x {
  position: absolute;
  top: 0px;
  z-index: 99999;
}
.scale-x .left {
  border-right: 1px solid #000;
}
.scale-x .bottom {
  text-align: center;
}
.scale-y {
  position: absolute;
  left: 0px;
  z-index: 99999;
}
.scale-y .top {
  border-bottom: 1px solid #000;
  display: inline-block;
  position: absolute;
  top: 0px;
}
.scale-y .right {
  vertical-align: middle;
  display: inline-block;
  position: absolute;
  top: 0px;
}
.cross {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}
</style>
