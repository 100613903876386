var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game"},[_c('div',{staticClass:"controls"},[_c('button',{attrs:{"disabled":_vm.map.config.zoom == 1},on:{"click":_vm.zoomOut}},[_vm._v("-")]),_c('button',{attrs:{"disabled":_vm.map.config.zoom == 4},on:{"click":_vm.zoomIn}},[_vm._v("+")])]),_c('div',{staticClass:"cross"},[_vm._v("+")]),_c('div',{attrs:{"id":"map"}},[_c('div',{style:('width: ' +
          _vm.map.config.tileWidth * _vm.map.config.sizeX * _vm.map.config.zoom +
          'px; height: ' +
          _vm.map.config.tileHeight * _vm.map.config.sizeY * _vm.map.config.zoom +
          'px; background-size: ' +
          _vm.map.config.tileWidth * _vm.map.config.zoom +
          'px;'),attrs:{"id":"map-content"}},[_vm._l((_vm.map.chunks),function(chunk){return _c('div',{key:chunk.id,staticClass:"chunk",style:('width: ' +
            _vm.map.config.tileWidth * _vm.map.config.lenX * _vm.map.config.zoom +
            'px; height: ' +
            _vm.map.config.tileHeight * _vm.map.config.lenY * _vm.map.config.zoom +
            'px; ' +
            'top: ' +
            chunk.top +
            'px; ' +
            'left: ' +
            chunk.left +
            'px;'),attrs:{"id":'chunk_' + chunk.id}})}),_vm._l((_vm.map.scaleX),function(x){return _c('div',{key:'sacale_x_' + x.id,staticClass:"scale-x",style:('left: ' +
            (x.pos - (_vm.map.config.tileWidth * _vm.map.config.zoom) / 2) +
            'px; width: ' +
            _vm.map.config.tileWidth +
            'px; height: ' +
            _vm.map.config.tileWidth +
            'px;'),attrs:{"id":'scale_x_' + x.id}},[_c('div',{staticClass:"left",style:('width: ' +
              (_vm.map.config.tileWidth * _vm.map.config.zoom) / 2 +
              'px; height: ' +
              _vm.map.config.tileWidth / 2 +
              'px;')}),_c('div',{staticClass:"bottom",style:('width: ' +
              _vm.map.config.tileWidth * _vm.map.config.zoom +
              'px; height: ' +
              _vm.map.config.tileWidth / 2 +
              'px;')},[_vm._v(" "+_vm._s(x.id)+" ")])])}),_vm._l((_vm.map.scaleY),function(y){return _c('div',{key:'sacale_y_' + y.id,staticClass:"scale-y",style:('top: ' +
            (y.pos - (_vm.map.config.tileHeight * _vm.map.config.zoom) / 2) +
            'px; width: ' +
            _vm.map.config.tileWidth +
            'px; height: ' +
            _vm.map.config.tileHeight * _vm.map.config.zoom +
            'px;'),attrs:{"id":'scale_y_' + y.id}},[_c('div',{staticClass:"top",style:('height: ' +
              (_vm.map.config.tileHeight * _vm.map.config.zoom) / 2 +
              'px; width: ' +
              _vm.map.config.tileWidth / 2 +
              'px;')}),_c('div',{staticClass:"right",style:('height: ' +
              _vm.map.config.tileHeight * _vm.map.config.zoom +
              'px; width: ' +
              _vm.map.config.tileWidth / 2 +
              'px; left: ' +
              _vm.map.config.tileWidth / 2 +
              'px; line-height: ' +
              _vm.map.config.tileHeight * _vm.map.config.zoom +
              'px;')},[_vm._v(" "+_vm._s(y.id)+" ")])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }